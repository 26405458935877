import React from "react"
import { graphql } from "gatsby"
import CustomLayout from '../layout/default'
import { Container, Row, Col } from '../../../shared-ui/components/ui/wrapper'
import SectionTitle from '../../../shared-ui/components/ui/section-title'
import ServiceBox from '../../../shared-ui/components/box-large-image/layout-two'
import Blog from '../../../shared-ui/components/blog/layout-one'
import Heading from '../../../shared-ui/components/ui/heading'
import Text from '../../../shared-ui/components/ui/text'
import Button from '../../../shared-ui/components/ui/button'
import HeroSection from '../components/hero-section'
import CTASection from '../components/cta-section'
import ContactFormSection from '../components/contact-form-section'
import TestimonialsSection from '../components/testimonials-section'
import styled from 'styled-components'
import {device} from '../../../shared-ui/theme'

export const BlogWrapper = styled.section `
    padding-top: 100px;
    padding-bottom: 70px;
    @media ${device.medium}{
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @media ${device.small}{
        padding-top: 60px;
        padding-bottom: 10px;
    }
`;

export const LeftBox = styled.div `
    margin-top: 30px;
    margin-bottom: 30px;
    @media ${device.medium}{
        margin-top: 0;
        margin-bottom: 40px;
    }
    @media ${device.small}{
        margin-bottom: 46px;
    }
`;

export const RecentBlogWrap = styled.div `
    margin-top: 30px;
`;

export const FeaturedBlogWrap = styled.div `
    @media ${device.small}{
        margin-bottom: 50px;
    }
`;


export const ServiceWrap = styled.div`
    background: #f8f8f8;
    padding-top: 100px;
    padding-bottom: 100px;
    @media ${device.medium}{
        padding-bottom: 80px;
    }
    @media ${device.small}{
        padding-bottom: 60px;
    }
    .box-item{
        margin-bottom: 50px;
    }
`;

export const ServiceBottom = styled.div`
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.40;
    color: #333333;
`;

export const AboutWrap = styled.section`
    padding-top: 100px;
    padding-bottom: 92px;
    @media ${device.medium}{
        padding-top: 80px;
        padding-bottom: 72px;
    }
    @media ${device.small}{
        padding-top: 60px;
        padding-bottom: 52px;
    }
`;

export const AboutHeading = styled.div``;

export const AboutText = styled.div``;

const IndexPage = ({location, data, props }) => {  

    
  const primaryServices = 
    [ 
      { path : "/solutions/commercial-office", content : data.commercialOffice },
      { path : "/solutions/executive-office", content : data.executiveOffice },
      { path : "/solutions/desk-office", content : data.deskOffice }
    ];

  const flexibleSolutions = 
    [ 
      { path : "/solutions/hot-desk", content : data.hotdeskOffice }
    ];

  // expected only one record will exist
  const homePage = data.homePageList.edges[0].node;
  const homePageBanner = homePage.heroBanner;
  const allContentfulBlog = data.allContentfulBlog;
  const solutions = homePage.featuredSolutions;

  const aboutSectionTitleStyle = {
    mb: '60px',
    responsive: {
        small: {
            mb: '47px'
        }
    }
  };
  const aboutHeadingStyle = {
    layout: 'highlight',
    mr: '48px'
};
const aboutSubheadingStyle = {
    as: 'h6',
    mt: '30px'
};
const aboutDescStyle = {
    fontSize: '18px',
    mb: '20px'
};
  return (    
    <CustomLayout location={location}>           
      <HeroSection title="Simplificados" subtitle="Servicios de Oficina" 
            text={homePageBanner.keyHighlights.join(", ")} bgImage={homePageBanner.backgroundImage.fluid}
            callToActionText={homePageBanner.callToActionText} callToActionPath={homePageBanner.callToActionUrl}  />
      <AboutWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            {...aboutSectionTitleStyle}                            
                            title="Somos más que una oficina, oficinas físicas administradas y oficinas virtuales para hacer crecer tu negocio."
                            subtitle="Nuestra Empresa"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={{ span: 4, offset: 1 }}>
                        <AboutHeading>
                           <Heading {...aboutHeadingStyle} ><mark>6</mark> AÑOS TRABAJANDO PARA NUESTROS CLIENTES EN CHIHUAHUA</Heading>
                           <Heading {...aboutSubheadingStyle} >Empresa orgullasemente Chihuahuense.</Heading>
                        </AboutHeading>
                    </Col>
                    <Col lg={{ span: 5, offset: 1 }}>
                        <AboutText>
                            <Text {...aboutDescStyle} >Nuestro objetivo es ayudar a nuestros clientes a que se puedan enfocar en su negocio y despreocuparse de las actividades que son necesarias para mantener una oficina.</Text>
                            {/*path && <Button to={path} icon="far fa-long-arrow-right" {...aboutBtnStyle}>Conocenos</Button>*/}
                        </AboutText>
                    </Col>
                </Row>
            </Container>
        </AboutWrap>

        {/*
            Service List Section
            Used as reference: https://preview.themeforest.net/item/mitech-it-solutions-and-services-company-react-gatsby-template/full_screen_preview/25766950?_ga=2.100700741.53582989.1629570869-1830585391.1612630584
        */}
        <ServiceWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            {...aboutSectionTitleStyle}                            
                            title="Contamos con una amplia gama de soluciones de trabajo, <br/> solicita informes hoy mismo."       
                            subtitle="Nuestras Soluciones"                                    
                        />                        
                    </Col>
                </Row>
                <Row>                
                    {solutions.map((service, i) => {
                        return(
                            <Col key={service.id} className="box-item" lg={4} md={6}>
                                <ServiceBox
                                    title={service.pageName}
                                    desc={service.solutionDescription}
                                    imageSrc={service.boxImage}
                                    path={`/solutions/${service.urlCode}/`}
                                    btnText="Explora"
                                />
                            </Col>
                        )
                    })}
                </Row>
                <Row>
                    <Col lg={12}>
                        {/*bottom_text && (
                            <SectionBottom> 
                                {bottom_text} 
                                {bottom_link_text && <Anchor path={path}>{bottom_link_text}</Anchor>}
                            </SectionBottom>
                        )*/}
                    </Col>
                </Row>
            </Container>
        </ServiceWrap>
      <BlogWrapper>
            <Container>
                <Row>
                    <Col lg={4}>
                        <LeftBox>
                            <SectionTitle
                                align="left"
                                title="Noticias"
                                subtitle="Blog y Noticias"
                            />
                            <RecentBlogWrap>
                                {/*<RecentPostList />*/}
                            </RecentBlogWrap>
                        </LeftBox>
                    </Col>
                    <Col lg={8}>
                        <Row>                            
                            {allContentfulBlog && allContentfulBlog.edges.map(featuredBlog => (
                                <Col md={6} key={featuredBlog.node.id}>
                                    <FeaturedBlogWrap>                                        
                                       <Blog content={{ 
                                            fields : {
                                                slug : 'blog/' + featuredBlog.node.urlCode  
                                            },
                                            frontmatter : 
                                            {
                                                title : featuredBlog.node.title,  
                                                'featured_image' : featuredBlog.node.images[0],
                                                format : 'contentfulImageFixed'
                                            },
                                            excerpt : featuredBlog.node.summary.summary
                                           }} />
                                    </FeaturedBlogWrap>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </BlogWrapper>
        <TestimonialsSection testimonials={homePage.featuredTestimonials} />
        <ContactFormSection />
        <CTASection />   
        
    </CustomLayout>)
}

IndexPage.defaultProps = {
    aboutSectionTitleStyle: {
        mb: '60px',
        responsive: {
            small: {
                mb: '47px'
            }
        }
    },
    aboutHeadingStyle: {
        layout: 'highlight',
        mr: '48px'
    },
    aboutSubheadingStyle: {
        as: 'h6',
        mt: '30px'
    },
    aboutDescStyle: {
        fontSize: '18px',
        mb: '20px'
    },
    aboutBtnStyle: {
        varient: 'texted',
        icondistance: '4px'
    }
}
 
export default IndexPage

export const pageQuery = graphql`
  query GetIndexPageContent {
    homePageBanner : contentfulHeroeBanner(key : {eq: "home-page-banner"}){
        headingTitle
        keyHighlights
        callToActionUrl
        callToActionText
        callToActionSubtext
        backgroundImage {
            fluid(maxWidth: 1900, quality: 100) {
                # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                ...GatsbyContentfulFluid
            }
        }
    }
    
    hotdeskOffice : contentfulWorkspaceGroup(title: {eq: "Hot Desk - Co-working"}) {
        id
        contentful_id
        title
        summary
        occupancyRange
        photo {
            fixed(width: 368, height:230, quality: 100) {
                # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                ...GatsbyContentfulFixed
            }
        }
        squareFootageRange
        basePrice
      }
    executiveOffice : contentfulWorkspaceGroup(title: {eq: "Oficina Ejecutiva"}) {
        id
        contentful_id
        title
        summary
        occupancyRange
        photo {
            fixed(width: 368, height:230, quality: 100) {
                # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                ...GatsbyContentfulFixed
            }
        }
        squareFootageRange
        basePrice
      } 
      commercialOffice : contentfulWorkspaceGroup(title: {eq: "Oficina Comercial"}) {
        id
        contentful_id
        title
        summary
        occupancyRange
        photo {
            fixed(width: 368, height:230, quality: 100) {
                # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                ...GatsbyContentfulFixed
            }
        }
        squareFootageRange
        basePrice
      } 
      deskOffice : contentfulWorkspaceGroup(title: {eq: "Escritorio de Trabajo (Dedicado)"}) {
        id
        contentful_id
        title
        summary
        occupancyRange
        photo {
            fixed(width: 368, height:230, quality: 100) {
                # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                ...GatsbyContentfulFixed
            }
        }
        squareFootageRange
        basePrice
      }

      solutions : allContentfulSolutionPage {
        edges {
            node {
                id
                contentful_id
                pageName
                pageBrowserTitle
                solutionDescription                            
                introTitle   
                urlCode     
                boxImage {
                    fixed(width: 368, height:230, quality: 100) {
                        # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                        ...GatsbyContentfulFixed
                    }
                  }          
            }
        }        
    }
    allContentfulBlog (sort: { fields: [publicPublishedDate], order: DESC }, limit: 2) {
      edges {
        node {
          id
          title
          urlCode
          publicPublishedDate  
          summary {
            summary
            childMarkdownRemark {
                html
            }
          } 
          images {
            fixed(width: 368, height:230, quality: 100) {
                # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                ...GatsbyContentfulFixed
            }
          }                   
        }
      }
    }
    homePageList : allContentfulHomePage (limit: 1) {
        edges {
          node {
            pageBrowserTitle            
            featuredSolutions {
                id
                contentful_id
                pageName
                pageBrowserTitle
                solutionDescription                            
                introTitle   
                urlCode     
                boxImage {
                    fixed(width: 368, height:230, quality: 100) {
                        # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                        ...GatsbyContentfulFixed
                    }
                }          
            }
            heroBanner {
                headingTitle
                keyHighlights
                callToActionUrl
                callToActionText
                callToActionSubtext
                backgroundImage {
                    fluid(maxWidth: 1900, quality: 100) {
                        # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                        ...GatsbyContentfulFluid
                    }
                }
            } 
            featuredTestimonials {
                customerName
                customerReviewMessage {                    
                    customerReviewMessage
                    childMarkdownRemark {
                        html
                    }
                }
                customerJobTitle
                postedDate
                ratingScore
            }
          }
        }
      }
  }
`
